/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Text, Title, ContactForm, Image } from '@swp/components'
import SiteHeader from '../components/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"ROKLEN10let"}>
        <SiteHeader set="" currentLanguage={undefined} />

        <Column className="pb--60 pt--60" name={"jr8spnf1q5"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper >
              
              <Text className="text-box fs--24" content={"<span style=\"font-weight: bold;\">Děkujeme za přijetí pozvání!</span><br>"}>
              </Text>

              <Title className="title-box" content={"ROKLEN slaví 10 let!&nbsp;"}>
              </Title>

              <Text className="text-box fs--22 mt--16" content={"<span style=\"font-weight: bold;\">&nbsp;Pro potvrzení pozvánky nám prosím sdělte pár informací</span>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <ContactForm className="--shape2 pl--0" style={{"maxWidth":740}} action={"/contact"} fields={[{"name":"Celé jméno","type":"text","required":true,"placeholder":"Zadejte celé jméno a prijímení","id":"kmc08tib0wmw"},{"name":"Kontaktní e-mail","type":"email","required":true,"placeholder":"Zadejte e-mail pro zaslání pozvánky","id":"ozoar0hzr246"},{"name":"FIRMA","type":"text","placeholder":"Zadejte název společnosti (volitelné)","id":"mk4xivmu47w1","required":false},{"name":"Odeslat","type":"submit","id":"2sgxk9wc2zbt"}]}>
              </ContactForm>

              <Image style={{"maxWidth":516}} sizes="(max-width: 1439px) 100vw, 1440px" srcSet={"https://cdn.swbpg.com/t/39934/1346752a1cc54964bf81ca8a6aa1025e_s=350x_.png 350w, https://cdn.swbpg.com/t/39934/1346752a1cc54964bf81ca8a6aa1025e_s=660x_.png 660w, https://cdn.swbpg.com/t/39934/1346752a1cc54964bf81ca8a6aa1025e_s=860x_.png 860w, https://cdn.swbpg.com/t/39934/1346752a1cc54964bf81ca8a6aa1025e_s=1400x_.png 1400w, https://cdn.swbpg.com/t/39934/1346752a1cc54964bf81ca8a6aa1025e_s=2000x_.png 2000w"} alt={""} src={"https://cdn.swbpg.com/t/39934/1346752a1cc54964bf81ca8a6aa1025e_s=860x_.png"}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}